var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"4"}},[_c('h4',{staticClass:"m-0 mt-1"},[_vm._v(" "+_vm._s(_vm.trainingName)+" ")])]),_c('b-col',{staticClass:"text-left",attrs:{"sm":"5"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Licence Start Date","label-for":"licence-start-date"}},[_c('div',{staticClass:"bag-button btn-common secondary-bg-color"},[_c('b-badge',{attrs:{"variant":_vm.STATUS_COLOR['Active']}},[_vm._v(" "+_vm._s(_vm.trainingAvailableStartDate)+" ")])],1)])],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Licence End Date","label-for":"licence-end-date"}},[_c('div',{staticClass:"bag-button btn-common secondary-bg-color"},[_c('b-badge',{attrs:{"variant":_vm.STATUS_COLOR['Suspended']}},[_vm._v(" "+_vm._s(_vm.trainingAvailableEndDate)+" ")])],1)])],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Seats Allocated","label-for":"licence-end-date"}},[_c('div',{staticClass:"bag-button btn-common secondary-bg-color"},[_c('b-badge',{attrs:{"variant":_vm.STATUS_COLOR['Applied']}},[_vm._v(" "+_vm._s(_vm.programTrainingSeatsCount)+" / "+_vm._s(_vm.seats_available)+" ")])],1)])],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"sm":"3"}},[_c('b-button',{staticClass:"add-participant-btn ml-1",attrs:{"disabled":_vm.isTrainingExpired(_vm.available_start_date, _vm.available_end_date),"variant":"primary"},on:{"click":_vm.openAddSideBarToggle}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon","size":"16"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Add Participant")])],1)],1)],1),_c('vue-good-table',{staticClass:"mt-3",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.directionIsRTL,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.perPage,
    },"style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"align-items-start mb-0 mt-0"},[_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
              name: 'champion-program-participant',
              params: { id: _vm.programId, participantId: props.row.id },
            }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])}),_c('program-training-user-list',{attrs:{"open":_vm.openAddSideBar,"program-id":_vm.programId,"training-id":_vm.trainingId,"seats-available":_vm.seats_available},on:{"close":_vm.closeSideBar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }