<template>
  <b-card>
    <b-row
      align-h="between"
      align-v="center"
    >
      <b-col
        sm="4"
        class="text-left"
      >
        <h4 class="m-0 mt-1">
          {{ trainingName }}
        </h4>
      </b-col>
      <b-col
        sm="5"
        class="text-left"
      >
      <b-row>
          <b-col sm="3">
            <b-form-group
              label="Licence Start Date"
              label-for="licence-start-date"
            >
              <div
                class="bag-button btn-common secondary-bg-color"
              >

                <b-badge :variant="STATUS_COLOR['Active']">
                  {{ trainingAvailableStartDate }}
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
              label="Licence End Date"
              label-for="licence-end-date"
            >

              <div
                class="bag-button btn-common secondary-bg-color"
              >
                <b-badge :variant="STATUS_COLOR['Suspended']">
                  {{ trainingAvailableEndDate }}
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
              label="Seats Allocated"
              label-for="licence-end-date"
            >
              <div
                class="bag-button btn-common secondary-bg-color"
              >
                <b-badge :variant="STATUS_COLOR['Applied']">
                  {{ programTrainingSeatsCount }} / {{ seats_available }}
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        sm="3"
        class="text-right"
      >
        <b-button
          class="add-participant-btn ml-1"
          :disabled="isTrainingExpired(available_start_date, available_end_date)"
          variant="primary"
          @click="openAddSideBarToggle"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="mr-50"
          /> 
          <span class="text-nowrap">Add Participant</span>
        </b-button>
      </b-col>
    </b-row>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'name'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <div class="align-items-start mb-0 mt-0">
            <router-link
              :to="{
                name: 'champion-program-participant',
                params: { id: programId, participantId: props.row.id },
              }"
              class="table-primary-link"
            >
              {{ props.row.name }}
            </router-link>
          </div>
        </div>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>
    <program-training-user-list
      :open="openAddSideBar"
      :program-id="programId"
      :training-id="trainingId"
      :seats-available="seats_available"
      @close="closeSideBar"
    />
  </b-card>

</template>

<script>
import { BButton, VBToggle, BDropdown, BDropdownItem, BCard, BRow, BCol, BBadge, BSidebar, BFormGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { localDateStringOrDateRangeStringToUtcRange, localeDateStringFromIsoDateTime, makeErrorToast } from "@/libs/utils";
import trainingsService from "@/services/trainingsService";
import { trainingStatus, trainingStatusDisplay } from '@models';
import { BE_API_URL } from "@/constants/app";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { isTrainingExpired } from "@/@aom-core/utils/utils";
import { 
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT, 
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
  STATUS_COLOR
} from "@/libs/utils";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import ProgramTrainingUserList from './ProgramTrainingUserList.vue';
export default {
  components: {
    ProgramTrainingUserList,
    BCard,
    VueGoodTable,
    TablePagination,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BSidebar,
    BFormGroup
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: false,
            placeholder: "Search Name",
          },
          width: "16em"
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: false,
            placeholder: "Search Email",
          },
          width: "16em"
        },
        {
          label: "Training Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: Object.values(trainingStatusDisplay),
          },
          width: "16em",
          sortable: false,
        },
        {
          label: "Training Completed",
          field: "completed_date",
          filterOptions: {
            enabled: true,
            placeholder: "Training Completed",
            filterValue: '',
          },
          width: "16em",
          type: 'date',
          thClass: 'vgt-left-align',
          tdClass: "vgt-left-align search-date-selector",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
          sortable: false,
        },
        {
          label: "Time Spent",
          field: "time",
          filterOptions: {
            enabled: false
          },
          width: "16em",
          sortable: false,
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      userList: [],
      searchTerm: "",
      trainingName: "",
      seats_available: 0,
      programTrainingSeatsCount: 0,
      available_start_date: '',
      available_end_date: '',
      openAddSideBar: false
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    programId() {
      return this.$route.params.id;
    },
    trainingId() {
      return this.$route.params.trainingId;
    },
    trainingAvailableStartDate() {
      return localeDateStringFromIsoDateTime(this.available_start_date);
    },
    trainingAvailableEndDate() {
      return localeDateStringFromIsoDateTime(this.available_end_date);
    }
  },
  mounted() {
    const elements = document.getElementsByName("vgt-completed_date");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  created() {
    this.loadTable();
  },
  methods: {
    openAddSideBarToggle() {
      this.openAddSideBar = true;
    },
    closeSideBar() {
      this.openAddSideBar = false;
      this.loadTable();
    },
    onPageChange(params) {
      this.page = params.currentPage;
    },
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadTable();   
    },
    
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadTable();
    },
 
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["status", "completed_date"]) {
        if (params.columnFilters[col]) {
          if (col == "status") {
            const status = Object.keys(trainingStatusDisplay).find(key => trainingStatusDisplay[key] === params.columnFilters[col]);
            if (status) {
              columnFilters.push({
                field: "status_id",
                value: status,
              });
            }
          } else if (col == "completed_date") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadTable();
      // this.loadUsers();
    },
    async loadTable() {
      try {
        this.isLoading = true;
        // Get training detail
        const training = await trainingsService.getProgramTraining(
          this.$route.params.id,
          this.$route.params.trainingId,
        );
        if (training && training.data) {
          this.trainingName = training.data.course_name;
          this.programTrainingSeatsCount = training.data.program_training_seats_count;
          this.seats_available = training.data.program_trainings[0].seats_available;
          this.available_start_date = training.data.program_trainings[0].available_start_date;
          this.available_end_date = training.data.program_trainings[0].available_end_date;
        }
        const allocatedUsers = await trainingsService.getTrainingUserList(
          this.$route.params.id,
          this.$route.params.trainingId,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = allocatedUsers.data.total;
        this.rows = this.parseUsers(allocatedUsers.data.items);
        
      } catch (e) {
        console.log(e)
        this.$log.error(e);
        this.$toast(makeErrorToast("Training log not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    async loadUsers() {
      try {
        this.isLoading = true;
        const userList = await trainingsService.getSeats(
          this.$route.params.id
        );
        this.userList = this.parseUsers(userList.data.items);
      } catch (e) {
        console.log(e)
        this.$log.error(e);
        this.$toast(makeErrorToast("Training log not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    parseUsers(items) {
      return items.map(item => {
        const trainingDetail = item.program_training_users && item.program_training_users.length
            ? item.program_training_users[item.program_training_users.length - 1]
            : null;
          return {
            id: item.id,
            name: item.full_name,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            status: trainingDetail ? trainingDetail.status.name : trainingStatusDisplay[trainingStatus.NOT_STARTED],
            time: this.getSpent(trainingDetail),
            completed_date: trainingDetail? trainingDetail.completed_date: null
          };
      });
    },
    updateSelectedUsers(value) {
      this.rows = [
        ...this.rows,
        ...value
      ];
    },
    getSpent(trainingDetail) {
      if (!trainingDetail) {
        return '0 minute';
      }
      const spentTime = trainingDetail.total_seconds_tracked || 0;
      if (spentTime === 0) {
        return '0 minute';
      }
      // Don't show if seconds less than 60
      if (spentTime < 60) {
        return '0 minute';
      }
      return `${Math.round(spentTime / 60)} minutes`;
    },
    onDownloadReport(type) {
      const programId = this.$route.params.id;
      const trainingId = this.$route.params.trainingId;
      const dowloadLink = `${BE_API_URL}/programs/${programId}/trainings/${trainingId}/report/download/${type}`;
      window.document.location = dowloadLink;
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page, STATUS_COLOR, isTrainingExpired
    };
  }
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>